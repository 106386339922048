export const prices = [
  {
    slogan: "un peu",
    quantity: 1,
    price: 230,
  },
  {
    slogan: "beaucoup",
    quantity: 2,
    price: 430,
  },
  {
    slogan: "passionnément",
    quantity: 3,
    price: 550,
  },
]

export const pricesOne = {
  quantity: 1,
  prices: [
    {
      hours: "1h",
      price: 265,
      cutPrice: 250,
    },
    {
      hours: "1h15",
      price: 315,
      cutPrice: 295,
    },
    {
      hours: "1h30",
      price: 360,
      cutPrice: 340,
    },
  ],
}

export const pricesTwo = {
  quantity: 2,
  prices: [
    {
      hours: "2h",
      price: 485,
      cutPrice: 445,
    },
    {
      hours: "2h15",
      price: 520,
      cutPrice: 480,
    },
    {
      hours: "2h30",
      price: 555,
      cutPrice: 515,
    },
    {
      hours: "2h45",
      price: 590,
      cutPrice: 550,
    },
    {
      hours: "3h",
      price: 625,
      cutPrice: 585,
    },
    {
      hours: "3h15",
      price: 655,
      cutPrice: 605,
    },
    {
      hours: "3h30",
      price: 695,
      cutPrice: 645,
    },
    {
      hours: "3h45",
      price: 725,
      cutPrice: 675,
    },
    {
      hours: "4h",
      price: 745,
      cutPrice: 695,
    },
    {
      hours: "4h15",
      price: 775,
      cutPrice: 725,
    },
    {
      hours: "4h30",
      price: 795,
      cutPrice: 745,
    },
  ],
}
