import React from "react"
import styled from "styled-components"
import pic1 from "../images/1.jpg"
import pic2 from "../images/2.jpg"
import pic3 from "../images/3.jpg"
import pic4 from "../images/4.jpg"
import pic5 from "../images/5.jpg"
import pic6 from "../images/6.jpg"
import pic7 from "../images/7.jpg"
import pic8 from "../images/8.jpg"
import pic9 from "../images/9.jpg"
import pic10 from "../images/10.jpg"
import { Button } from "../components/button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Bold,
  Row,
  Section,
  Subtitle,
  Title,
  HeroRow,
  Testimonial,
  HeroButtons,
  RightPane,
  BackgroundFadedTitle,
  TriangleSvg,
  ClassCardsContainer,
  ClassCard,
} from "../components/styled"
import { svgs } from "../components/svgs"
import { colors } from "../constants/colors"
import { mq } from "../constants/media-queries"
import dancer from "../images/dancer.png"
import { prices } from "../content/prices"
import { graphql } from "gatsby"
import { zIndexes } from "../constants/variables"
import { Slider } from "../components/slider"

const Hero = styled.div`
  background-image: linear-gradient(
    135deg,
    ${colors.black1} 0%,
    ${colors.black2} 100%
  );
  height: 150vw;
  max-height: 90vh;
  padding-top: 80px;
  ${mq.sm`
    height: 80vh;
    max-height: unset;
    padding-top: 15vh;
  `}
`

const Dancer = styled.img`
  grid-area: 1/1/2/2;
  width: 65%;
  height: 100%;
  justify-self: center;
  z-index: ${zIndexes.low};
  transform: translate(2vw, 3vh);
  ${mq.md`
  width: 80%;
  height: 120%;
  justify-self: end;
  transform: translate(2vw, 3vh);
  `}
  ${mq.lg`
  width: 90%;
  height: 130%;
  `}
  object-fit: contain;
`

const AboutRow = styled(Row)`
  max-width: 1200px;
`

const Numbers = styled.div`
  display: grid;
  padding: 40px;
  gap: 40px;
  ${mq.sm`
  grid-auto-flow: column;
  `}
`

const AboutIntroText = styled.p`
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
  text-align: center;
  line-height: 1.5;
`

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Number = styled.p`
  font-size: 60px;
  margin-bottom: 16px;
  font-weight: 500;
  color: ${colors.purple};
  cursor: default;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    color: ${colors.pink};
  }
`
const NumberText = styled.p`
  font-size: 20px;
  text-align: center;
`
const PhotoSection = styled(Section)`
  overflow-x: hidden;
  display: none;
  ${mq.sm`
  display: block;
  padding: 50px 0;
  `}
`

const PhotoSlide = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${mq.md`
  grid-template-columns: repeat(5, 1fr);
  `}
  gap: 40px;
  justify-items: center;
  align-items: center;
  transform: translateX(5vw);
  ${mq.md`
  transform: translateX(3vw);
  `}
  margin-bottom: 40px;
`

const BottomPhotoSlide = styled(PhotoSlide)`
  margin-bottom: 0;
  transform: translateX(-5vw);
  ${mq.md`
  transform: translateX(-3vw);
  `}
`

const Photo = styled.div`
  overflow: hidden;
  border-radius: 15px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &:nth-of-type(4),
  &:nth-of-type(5) {
    display: none;
    ${mq.md`
    display: block;
    `}
  }
  ${mq.md`
    border-radius: 20px;
    grid-template-columns: repeat(5, 1fr);
  `}
`

const MobilePhotoSection = styled(Section)`
  ${mq.sm`
  display: none
`}
  display: flex;
  flex-direction: column;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const PriceCardsContainer = styled.div`
  display: grid;
  gap: 30px;
  ${mq.sm`
  grid-template-columns: repeat(3, 1fr);
  gap: 4vw;
  `}
  ${mq.md`
  grid-template-columns: repeat(3, 1fr);
  gap: 5vw;
  `}
`
const PriceCard = styled.div`
  background: ${colors.white};
  text-align: center;
  padding-bottom: 20px;
  transition: all 0.25s ease;
  &:hover,
  &:focus {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    transform: translateY(-5px);
  }
`
const PriceCardSlogan = styled.p`
  color: ${colors.purple};
  text-transform: uppercase;
  border-bottom: 1px solid ${colors.greyE};
  font-size: 24px;
  padding: 24px;
  font-family: "Oswald", sans-serif;
`
const PriceCardQuantity = styled.p`
  border-bottom: 1px solid ${colors.greyE};
  font-size: 20px;
  padding: 24px;
  font-weight: 600;
`

const PriceBox = styled.div`
  color: ${colors.black7};
  > p {
    margin: 16px;
    line-height: 1.5;
  }
`

const Price = styled.span`
  color: ${colors.black4};
  font-size: 46px;
  margin: 0 4px 0 10px;
`

const PricesButtonBox = styled.div`
  margin-top: 30px;
  ${mq.sm`
  margin-top: 4vw;
`}
  ${mq.lg`
  margin-top: 60px;
`}
  display: flex;
  justify-content: center;
`

const navItems = [
  {
    title: "Horaires",
    path: "/danse/horaires",
  },
  {
    title: "Tarifs",
    path: "/danse/tarifs",
  },
  {
    title: "Infos",
    path: "/infos",
  },
]

const animTime = 500

const DancePage = ({ data }) => {
  const { sanityDanceHeroButton, allSanityDanceClassCard } = data
  return (
    <Layout navItems={navItems} darkTheme>
      <SEO title="Danse" />
      <Hero id="hero">
        <HeroRow>
          <Testimonial>
            "Super école de danse. Les profs, les cours, le spectacle, ... tout
            est génial." <span>Oanel B.</span>
          </Testimonial>
          <RightPane>
            <BackgroundFadedTitle dance>danse</BackgroundFadedTitle>
            <TriangleSvg left>{svgs.triangle}</TriangleSvg>
            <Dancer src={dancer} alt="danseuse"></Dancer>
          </RightPane>
          <HeroButtons>
            <Button to="/infos" color="pink">
              Rejoignez-nous
            </Button>
            {!sanityDanceHeroButton?.hidden &&
              sanityDanceHeroButton?.title &&
              sanityDanceHeroButton?.linkto && (
                <Button
                  color="white"
                  outline="true"
                  to={sanityDanceHeroButton?.linkto}
                >
                  {sanityDanceHeroButton?.title}
                </Button>
              )}
          </HeroButtons>
        </HeroRow>
      </Hero>
      <Section style={{ zIndex: zIndexes.veryLow }}>
        <AboutRow>
          <Title>Notre école</Title>
          <AboutIntroText>
            G Fit Dance est une école <Bold>bienveillante et familiale</Bold>.
          </AboutIntroText>
          <AboutIntroText>
            Nos professeurs <Bold>chevronnés et passionnés</Bold> vous
            accompagnent dans de nombreux
            <br /> styles quel que soit votre niveau.
          </AboutIntroText>
          <Numbers>
            <NumberBox
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-delay="100"
              data-sal-easing="ease-out"
            >
              <Number>500 +</Number>
              <NumberText>membres qui nous font confiance</NumberText>
            </NumberBox>
            <NumberBox
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-delay="200"
              data-sal-easing="ease-out"
            >
              <Number>20 +</Number>
              <NumberText>années d'expérience</NumberText>
            </NumberBox>
            <NumberBox
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              <Number>30 +</Number>
              <NumberText>cours disponibles</NumberText>
            </NumberBox>
          </Numbers>
        </AboutRow>
      </Section>
      <Section id="classes">
        <Row>
          <Subtitle>Danse</Subtitle>
          <Title>Les cours</Title>
          <ClassCardsContainer>
            {allSanityDanceClassCard.classesCards.map(({ node }, index) => (
              <li
                data-sal="slide-up"
                data-sal-duration={animTime}
                data-sal-delay={`${index}00`}
                data-sal-easing="ease-out"
                key={node.title}
              >
                <ClassCard hasDesc={!!node.desc}>
                  <p className="front">{node.title}</p>
                  {node.desc && <p className="back">{node.desc}</p>}
                </ClassCard>
              </li>
            ))}
          </ClassCardsContainer>
          <PricesButtonBox
            data-sal="slide-up"
            data-sal-duration={animTime}
            data-sal-delay={`100`}
            data-sal-easing="ease-out"
          >
            <Button to="/danse/horaires" color="pink" outline="true">
              VOIR LES HORAIRES
            </Button>
          </PricesButtonBox>
        </Row>
      </Section>
      <MobilePhotoSection>
        <img src={pic1} alt="danseurs" />
        <img src={pic2} alt="danseurs" />
        <img src={pic8} alt="danseurs" />
      </MobilePhotoSection>
      <PhotoSection>
        <div
          data-sal="slide-left"
          data-sal-duration="600"
          data-sal-delay="200"
          data-sal-easing="ease-out"
        >
          <PhotoSlide>
            <Photo>
              <img src={pic3} alt="danseurs" />
            </Photo>
            <Photo>
              <img src={pic1} alt="danseurs" />
            </Photo>
            <Photo>
              <img src={pic2} alt="danseurs" />
            </Photo>
            <Photo>
              <img src={pic5} alt="danseurs" />
            </Photo>
            <Photo>
              <img src={pic9} alt="danseurs" />
            </Photo>
          </PhotoSlide>
        </div>
        <div
          data-sal="slide-right"
          data-sal-duration="600"
          data-sal-delay="200"
          data-sal-easing="ease-out"
        >
          <BottomPhotoSlide>
            <Photo>
              <img src={pic6} alt="danseurs" />
            </Photo>
            <Photo>
              <img src={pic4} alt="danseurs" />
            </Photo>
            <Photo>
              <img src={pic7} alt="danseurs" />
            </Photo>
            <Photo>
              <img src={pic8} alt="danseurs" />
            </Photo>
            <Photo>
              <img src={pic10} alt="danseurs" />
            </Photo>
          </BottomPhotoSlide>
        </div>
      </PhotoSection>
      <Section id="prices">
        <Subtitle>Danse</Subtitle>
        <Title>Les formules</Title>
        <Row>
          <PriceCardsContainer>
            {prices.map((price, index) => (
              <PriceCard key={price.price}>
                <PriceCardSlogan>{price.slogan}</PriceCardSlogan>
                <PriceCardQuantity>
                  {price.quantity} cours / semaine
                </PriceCardQuantity>
                <PriceBox>
                  <p>
                    à.p.d. <Price>{price.price}</Price> € / an
                  </p>
                  <p>pour {price.quantity} cours d’1 h</p>
                </PriceBox>
              </PriceCard>
            ))}
          </PriceCardsContainer>
          <PricesButtonBox>
            <Button to="/danse/tarifs" color="pink" outline="true">
              VOIR LES TARIFS COMPLETS
            </Button>
          </PricesButtonBox>
        </Row>
      </Section>
      <Section id="testimonials">
        <Row>
          <div
            data-sal="slide-left"
            data-sal-duration="600"
            data-sal-delay="200"
            data-sal-easing="ease-out"
          >
            <Slider
              slides={[
                {
                  content:
                    "Une école de danse qui prend soin de ses élèves comme une grande famille",
                  author: "Laura C.",
                },
                {
                  content: "La meilleure école de danse, super familiale!",
                  author: "Audrey L.",
                },
              ]}
            />
          </div>
        </Row>
      </Section>
    </Layout>
  )
}

export default DancePage

export const pageQuery = graphql`
  query {
    allSanityDanceClassCard {
      classesCards: edges {
        node {
          title
          desc
        }
      }
    }
    sanityDanceHeroButton {
      title
      hidden
      linkto
    }
  }
`
