import React, { useState } from "react"
import { colors } from "../constants/colors"
import styled from "styled-components"
import { svgs } from "./svgs"
import { mq } from "../constants/media-queries"

const TestimonialsBox = styled.div`
  background: ${colors.greyE};
  line-height: 1.5;
  border-radius: 10px;
  min-height: 300px;
  display: flex;
  position: relative;
  font-family: "Oswald", sans-serif;
  transition: all 0.3s ease;
  padding: 50px 50px 100px;
  font-size: 30px;
  span {
    margin-left: 20px;
    color: ${colors.black7};
    font-size: 26px;
  }
  ${mq.sm`
    font-size: 46px;
  padding: 80px;
`}
`

const Icon = styled.div`
  position: absolute;
  top: -20px;
  left: -10px;
  width: 60px;
  height: 60px;
  ${mq.sm`
  width: 100px;
  height: 100px;
  `}
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const SlideNav = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`
const SlideNavButton = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ checked }) => (checked ? colors.black7 : colors.greyA)};
  cursor: pointer;
`

export const Slider = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <TestimonialsBox>
      <Icon>{svgs.quote}</Icon>
      <p>
        "{slides[activeIndex]?.content}"
        <span>{slides[activeIndex]?.author}</span>
      </p>
      <SlideNav>
        {slides.map((item, index) => (
          <SlideNavButton
            aria-label={`display testimonial ${index}`}
            key={item.author}
            checked={activeIndex === index}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </SlideNav>
    </TestimonialsBox>
  )
}
